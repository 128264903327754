import './App.scss';

import { lazy, Suspense } from 'react';

import Experiences from './components/Experiences';

const Header = lazy(() => import('./components/Header'));
const Hero = lazy(() => import('./components/Hero'));
const Expertises = lazy(() => import('./components/Expertises'));
const About = lazy(() => import('./components/About'));
const FAQ = lazy(() => import('./components/FAQ'));
const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));

function App(): JSX.Element {
  return (
    <>
      <div className="flex justify-center">
        <div className="md:max-w-screen-2xl">
          <Suspense fallback={null}>
            <Header />
          </Suspense>
          <div className="md:mx-6 md:mb-6 mx-3 mb-3">
            <main className="flex flex-col md:gap-12 gap-6">
              <Suspense fallback={null}>
                <Hero />
              </Suspense>
              <Suspense fallback={null}>
                <Expertises />
              </Suspense>
              <Suspense fallback={null}>
                <Experiences />
              </Suspense>
              <Suspense fallback={null}>
                <About />
              </Suspense>
              <Suspense fallback={null}>
                <FAQ />
              </Suspense>
              <Suspense fallback={null}>
                <Contact />
              </Suspense>
              <footer>
                <Suspense fallback={null}>
                  <Footer />
                </Suspense>
              </footer>
              <Suspense fallback={null}>
                <ScrollToTop />
              </Suspense>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
