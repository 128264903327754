import {
  Accordion,
  AccordionItem,
  Button,
  Chip,
  Link,
} from '@nextui-org/react';
import { Element } from 'react-scroll';

type IntroText = {
  title: string;
  description: string;
};

type Responsibility =
  | string
  | {
      additionalResponsibilities: string[];
    };

type Experience = {
  company: string;
  period: string;
  responsibilities?: Responsibility[];
  other?: string;
  tooling?: string;
};

type LinkButton = {
  label: string;
  href: string;
};

const introText: IntroText = {
  title: "I've worked with clients across various domains",
  description:
    'This has strengthened my professional growth and enhanced my ability to add value.',
};

const experiencesData: Experience[] = [
  {
    company: 'Sabbatical, Australia - Explorer',
    period: 'Dec 2022 - Sept 2024 · 1 year, 10 months',
    other:
      'During my sabbatical, I broadened my horizons by traveling, doing seasonal work, meeting new people, exploring new interests, renewing my creativity and energy, and taking the next steps in my career.',
  },
  {
    company: 'Incentro, Amsterdam - Digital Experience Consultant',
    period: 'Feb 2016 - Dec 2022 · 6 years, 11 months',
    other:
      'As a Digital Experience Consultant, I have had unique opportunities to tackle challenges and develop solutions in multidisciplinary Agile teams for clients in sectors such as healthcare (Artsenfederatie KNMG), e-commerce (ECI), legal services (Van Doorne), and financial services (De Volksbank).',
  },
  {
    company:
      'De Volksbank, Utrecht / Den Bosch — Front-end Developer (via Incentro)',
    period: 'Jan 2018 - Nov 2022 · 4 years, 11 months',
    responsibilities: [
      'Maintaining and innovating the websites of the brands SNS, ASN Bank, RegioBank, and BLG Wonen (De Volksbank).',
      'Development of a comprehensive multi-brand UI component library, consisting of various reusable tools, theming, elements, components, and documentation.',
      'Development of a campaign section on the ASN Bank website that effectively translates their mission.',
      'Development of a cookie consent functionality to manage cookie preferences.',
      'Development of Angular applications that inform users and clients and enable them to:',
      {
        additionalResponsibilities: [
          'Make an appointment with an advisor for various topics;',
          'Make informed decisions by evaluating current mortgage rates;',
          'Assess their financial resilience and help them gain control over their finances;',
          'Make decisions by evaluating mortgage options and scheduling an appointment with an advisor.',
        ],
      },
      'Creating a testing strategy and implementing test automation to improve quality.',
    ],
    tooling:
      'npm, Git, HTML, CSS, SASS, JavaScript, TypeScript, Angular, Webpack, ESLint, Stylelint, Prettier, XperienceCentral, Jest, Jasmine, Selenium, Sketch, Zeplin, InVision.',
  },
  {
    company: 'Van Doorne, Amsterdam — Front-end Developer (via Incentro)',
    period: 'Aug 2017 - Jan 2018 · 6 months',
    responsibilities: [
      'Development of a UI component library, consisting of various reusable tools, theming, elements, components, and documentation.',
      'Creating new layouts that utilize the newly developed UI component library.',
      'Delivery of the new business and recruitment website.',
    ],
    tooling:
      'npm, Git, HTML, CSS, SASS, JavaScript, Gulp, Babel, ESLint, Stylelint, Prettier, Handlebars, Pattern Lab, InVision.',
  },
  {
    company:
      'Artsenfederatie KNMG, Amsterdam — Front-end Developer (via Incentro)',
    period: 'Jun 2017 - Jul 2017 · 2 months',
    responsibilities: [
      'Expansion of the existing UI component library, consisting of various reusable elements and components for the search functionality, including searching, filtering, navigating, and displaying search results.',
      'Utilization of the Web Speech API to enable voice-activated search.',
    ],
    tooling: 'npm, Git, HTML, CSS, SASS, JavaScript, Web Speech API, InVision.',
  },
  {
    company: 'eci, Houten — Front-end Developer (via Incentro)',
    period: 'Aug 2016 - Apr 2017 · 9 months',
    responsibilities: [
      'Development of a UI component library, consisting of various reusable tools, theming, elements, components, and documentation.',
      'Creating new layouts that utilize the newly developed UI component library.',
      'Delivery of the new e-commerce website.',
    ],
    tooling:
      'HTML, CSS, SASS, JavaScript, Babel, Gulp, ESLint, Stylelint, Prettier, Mustache, Pattern Lab.',
  },
];

const linkButton: LinkButton = {
  label: 'View on LinkedIn',
  href: 'https://www.linkedin.com/in/daniël-staal/',
};

const Experiences: React.FC = (): JSX.Element => {
  return (
    <>
      <Element
        name="experiences"
        className="bg-gradient-to-tl from-brand-bg from-30% to-brand-bg-accent to-100% rounded-3xl px-3 py-6 lg:px-12 lg:py-16"
      >
        <div className="lg:w-2/3">
          <div className="flex items-center mb-6">
            <Chip
              variant="flat"
              size="lg"
              classNames={{
                base: 'bg-white shadow-sm shadow-primary/20',
                content: 'text-primary font-semibold',
              }}
            >
              Experiences
            </Chip>
          </div>
          <h2 className="text-4xl md:text-6xl font-semibold tracking-tight text-primary mb-6">
            {introText.title}
          </h2>
          <p className="pb-6 text-lg leading-relaxed">
            {introText.description}
          </p>
          <Accordion className="px-0 mb-8">
            {experiencesData.map((experience, index) => (
              <AccordionItem
                key={index}
                aria-label={experience.company}
                subtitle={<p className="flex">{experience.period}</p>}
                title={experience.company}
              >
                {experience.responsibilities &&
                  experience.responsibilities.length > 0 && (
                    <div className="mb-3">
                      <h6 className="font-semibold mb-1 text-primary">
                        Work and responsibilities
                      </h6>
                      {Array.isArray(experience.responsibilities) ? (
                        experience.responsibilities.length === 1 ? (
                          <p>{experience.responsibilities[0]}</p>
                        ) : (
                          <ul className="list-disc list-inside">
                            {experience.responsibilities.map((item, idx) =>
                              typeof item === 'string' ? (
                                <li key={idx}>{item}</li>
                              ) : (
                                <ul
                                  className="list-disc list-inside ml-6"
                                  key={idx}
                                >
                                  {item.additionalResponsibilities.map(
                                    (additionalItem, addIdx) => (
                                      <li key={addIdx}>{additionalItem}</li>
                                    )
                                  )}
                                </ul>
                              )
                            )}
                          </ul>
                        )
                      ) : (
                        <p>{experience.responsibilities}</p>
                      )}
                    </div>
                  )}
                {experience.tooling && (
                  <div className="mb-3">
                    <h6 className="font-semibold mb-1 text-primary">
                      Competencies
                    </h6>
                    <p>{experience.tooling}</p>
                  </div>
                )}
                {experience.other && (
                  <div className="mb-3">
                    <p>{experience.other}</p>
                  </div>
                )}
              </AccordionItem>
            ))}
          </Accordion>
          <Button
            as={Link}
            color="primary"
            radius="full"
            size="lg"
            showAnchorIcon
            variant="bordered"
            target="blank"
            href={linkButton.href}
          >
            {linkButton.label}
          </Button>
        </div>
      </Element>
    </>
  );
};

export default Experiences;
